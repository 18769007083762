<template>
  <div class='container'>
    <div class="total">
      <p>共<b style="margin: 0 1.3333vw;">{{total}}</b>个课程</p>
      <div style="display: flex;align-items: center;" @click="isShow=true">
        <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
        <p style="color: #666;font-size: 2.9333vw;">筛选</p>
      </div>
    </div>
    <div class="list" v-show="list.length>0">
      <div class="list-content" v-for="item in list" :key="item.id" @click="$router.push({path:'courseDetail',query:{id:item.id}})">
        <van-image
          width="22.4vw"
          height="22.4vw"
          :src="item.imgLearning"
          fit="cover"
          radius="2.6667vw"
        />
        <div style="flex:1;display: flex;flex-direction: column;justify-content: space-evenly;margin-left: 3.2vw;height: 27.2vw;">
          <p class="omit" style="font-size: 3.7333vw;font-weight: 550;-webkit-line-clamp:2">{{item.title}}</p>
          <div class="omit" style="color: #7d7d7d;font-size: 3.2vw;-webkit-line-clamp:2;display: flex;align-items: flex-start;">
            <div style="display: flex;align-items: center;margin-right: 1.3333vw;">
              <img src="@/assets/my/teacher.png" alt="" style="width: 10px;height: 10px;margin-right: 1.3333vw;">
              <p>讲师:</p>
            </div>
            <p style="flex:1">{{item.authorName}}</p>
          </div>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <van-empty description="暂无心理课程" v-show="list.length<=0"></van-empty>
    <van-popup v-model="isShow" position="right" :style="{ height: '100%',width:'75%' }" close-on-click-overlay>
      <div class="popup">
        <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 6.6667vw;">
          <p style="font-size: 4.2667vw;font-weight: 550;">列表筛选</p>
          <img src="@/assets/project/close.png" alt="" style="width: 6.4vw;height: 6.4vw;" @click="isShow=false">
        </div>
        <div class="popup-list">
          <div class="option">
            <p>级别</p>
            <div class="options">
              <div :class="['options-content',gradeIndex===index?'black':'']" v-for="(item,index) in grades" :key="index" @click="setGardeIndex(index)">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="option">
            <p>年级</p>
            <div class="options">
              <div :class="['options-content',listQuery.grade === grade.grade ? 'black' : '']" v-for="grade in grades[gradeIndex].children" :key="grade.name" @click="setGarde(grade.grade)">
                {{ grade.name }}
              </div>
            </div>
          </div>
          <div class="option select">
            <p>专题</p>
            <div class="selectInput">
              <input type="text" v-model="popupName" @click="isPopup=true">
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
        </div>
        <div class="btns">
          <button style="color: #47D5B2;" @click="reset">重置</button>
          <button @click="search">搜索</button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="选择专题"
        value-key="name"
        show-toolbar
        :columns="cats"
        @confirm="onConfirm"
        @cancel="isPopup=false"
      />
    </van-popup>
  </div>
</template>

<script>
import {bottomLoading} from '@/utils/util.js'
import {learningList,learningCats} from '@/api/learn.js'
export default {
  data(){
    return{
      isShow:false,
      isPopup:false,
      list:[],
      listQuery: {
        pageNo: 1,
        pageSize: 10,
        catId: '',
        grade: ''
      },
      total:0,
      totalPageCount:0,
      popupName:'',
      cats:[],
      grades: [{
                    name: '全部',
                    children: [
                        {
                            grade: '',
                            name: '全部'
                        }
                    ]
                }, {
                    name: '小学',
                    children: [
                        {
                            grade: 0,
                            name: '一年级'
                        },
                        {
                            grade: 1,
                            name: '二年级'
                        }, {
                            grade: 2,
                            name: '三年级'
                        }, {
                            grade: 3,
                            name: '四年级'
                        }, {
                            grade: 4,
                            name: '五年级'
                        }, {
                            grade: 5,
                            name: '六年级'
                        }
                    ]
                }, {
                    name: '初中',
                    children: [
                        {
                            grade: 6,
                            name: '初一'
                        },
                        {
                            grade: 7,
                            name: '初二'
                        }, {
                            grade: 8,
                            name: '初三'
                        }
                    ]
                }, {
                    name: '高中',
                    children: [
                        {
                            grade: 9,
                            name: '高一'
                        },
                        {
                            grade: 10,
                            name: '高二'
                        }, {
                            grade: 11,
                            name: '高三'
                        }
                    ]
                }
      ],
      gradeIndex:0,
    }
  },
  created(){
    this.getList()
    this.getCats()
  },
  mounted(){
    bottomLoading(this.$refs.bottom,this.loadNextPage)
  },
  methods:{
    async getList(){
      const listQuery = this.listQuery
      const formData = {}
      for (const el in listQuery) {
          if (listQuery[el] !== -1 && listQuery[el] !== '' && listQuery[el] !== '全部') {
              formData[el] = listQuery[el]
          }
      }
      const res = await learningList(formData)
      this.list=res.data.list
      this.total = res.data.recordCount
      this.totalPageCount=res.data.totalPageCount
    },
    async getCats () {
        const res = await learningCats()
        res.data.unshift({id:-1,name:'全部'})
        this.cats = res.data
    },
    setGardeIndex(index){
      this.gradeIndex = index
      this.listQuery.grade = this.grades[index].children[0].grade
      // this.listQuery.pageNo = 1
      // this.getList()
    },
    setGarde(grade){
      this.listQuery.grade = grade
      // this.listQuery.pageNo = 1
      // this.getList()
    },
    reset(){
      this.gradeIndex=0
      this.listQuery= {
        pageNo: 1,
        pageSize: 10,
        catId: '',
        grade: ''
      },
      this.popupName=''
      this.getList()
      this.isShow=false
    },
    search(){
      this.listQuery.pageNo=1
      this.getList()
      this.isShow=false
    },
    onConfirm(value){
      this.listQuery.catId=value.id
      this.popupName=value.name
      this.isPopup=false
    },
    async loadNextPage() {
      if(this.listQuery.pageNo<this.totalPageCount){
        this.listQuery.pageNo++
        const formData={}
        for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
        }
        const res = await learningList(formData)
        this.totalPageCount=res.data.totalPageCount
        this.total=res.data.recordCount
        this.list.push(...res.data.list)
      }
      // 在这里编写请求下一页数据的逻辑
      // 可以使用AJAX或其他方式向服务器发送请求
      // 将获取的数据添加到this.list数组中
      // 更新this.page变量为下一页的页码
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 4vw 3.2vw;
  background-color: rgba(248, 248, 248, 1);
  min-height: 100%;
  .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.9333vw;
  }
  .list{
    margin-top: 4vw;
    // padding: 0 1.8667vw;
    .list-content{
      padding: 1.3333vw;
      margin-bottom: 4vw;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 2.6667vw;
    }
  }
  .popup{
    position: relative;
    padding: 5.8667vw 5.3333vw 13.8667vw 5.3333vw;
    .popup-list{
      height: 80vh;
      overflow: scroll;
      width: 100%;
      // margin-top: 6.6667vw;
      .option{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6667vw;
        color: #333;
        .options{
          // margin-top: 2.6667vw;
          display: flex;
          flex-wrap: wrap;
          .options-content{
            margin-right:3.2vw ;
            width: 19.2vw;
            height: 12.2667vw;
            line-height: 12.2667vw;
            text-align: center;
            background-color: #F5F7F9 ;
            border-radius: 13.3333vw;
            // border: .2667vw solid #bbb;
            &:nth-of-type(3n){
              margin-right: 0;
            }
            margin-top: 3.2vw;
          }
          .options-content.black{
            background-color: #10CA9B;
            color: #fff;
          }
        }
        .selectInput{
          position: relative;
          input{
            padding-right: 9.3333vw;
            width: 100%;
          }
          .arrow{
            position: absolute;
            top: 53%;
            right:2.4vw;
            transform: translateY(-50%);
          }
        }
        input{
          margin-top:1.3333vw ;
          padding: 0 5.3333vw;
          height: 12.2667vw;
          background: #F5F7F9;
          border-radius: 13.3333vw;
          // border: .2667vw solid rgba(187, 187, 187, 1);
          &::placeholder{
            color: #666;
          }
        }
        p{
          font-weight: 550;
        }
      }
    }
    .btns{
      position: fixed;
      display: flex;
      align-items: center;
      padding: 0 6.6667vw;
      width: 100%;
      height: 16vw;
      left: 0;
      bottom: 0;
      button{
        flex: 1;
        font-size: 4.2667vw;
        color: #fff;
        height: 12.2667vw;
        line-height: 12.2667vw;
        background: #EFF9F8;
        border-radius: 13.3333vw;
        &:last-child{
          margin-left: 6.6667vw;
          background: #10CA9B;
        }
      }
    }
  }
}
</style>